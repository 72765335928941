
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import accountingApi from "@/core/services/AccountingApi";
import membersApi from "@/core/services/MembersApi";
import promotionsApi from "@/core/services/PromotionsApi";
import { Form, Field } from "vee-validate";
import { object, string, number } from "yup";
import { hideModal } from "@/core/helpers/dom";
import { ElMessage } from "element-plus";
import moment from "moment";

export default defineComponent({
    name: "Accounting",
    components: { Form, Field },
    data() {
        return {
            // Biller filters
            billerBailliage: "",
            billerFrom: `${new Date().getFullYear()}-01-01`,
            billerFromYear: `${new Date().getFullYear()}` as any,
            billerTo: "",
            billerPerimeter: "",
            billerDues: [] as any,
            billerPriceCategories: [] as any,

            intoOperations: true,
            intoSynthese: false,
            intoContacts: false,
            disabledAll: false,
            opeIdToDelete: "",
            loading: true,
            editing: false,
            editingOpe: false,
            billerOperations: [] as any,
            billerSynthesis: [] as any,
            selectedValue: "",
            periodFrom: "",
            periodTo: "",
            billerSearch: "",
            perimeters: [
                {
                    id: "CDR",
                    label: "CDR",
                },
                {
                    id: "OMGD",
                    label: "OMGD",
                },
            ],
            main_contacts: [] as any,
            additional_contacts: [] as any,
            dueTypes: [] as any,
            promTypes: [] as any,
            priceCategories: [] as any,
            promPriceCategories: [] as any,
            dueTypesFiltered: [] as any,
            priceCategoriesFiltered: [] as any,
            operations_types: [] as any,
            dueSupertype: "",
            priceCatSupertype: "",
            cdrFrom: "",
            cdrAt: "",
            globalFrom: "",
            globalAt: "",
            OMGDFrom: "",
            OMGDAt: "",
            perm_pos: "",
            nBailliages: [] as any,
            newOperation: {
                is_debit: "",
                perimeter: "",
                type: "",
                description: "",
                quantity: "",
                unit_price: "",
            },
            newContact: {
                id: "",
                type: "member",
                userprofile: {
                    id: "",
                    lastname: "",
                    firstname: "",
                },
                email: "",
            },
            newContactName: "",
            remoteMembers: [] as any,
            cdrCardValue: "",
            omgdCardValue: "",
            globalCardValue: "",
            editingOpeId: "",
            isBailli: false,
            allBailliagesData: [] as any,
        };
    },
    setup() {
        const addOperationRef = ref<null | HTMLElement>(null) as any;
        const addContactModalRef = ref<null | HTMLElement>(null) as any;

        const operationSchema = object().shape({
            opeIsDebit: string().required().label("Type").nullable(),
            opePerimeter: string().required().label("Perimeter").nullable(),
            opeType: string().required().label("Operation type").nullable(),
            opeDescription: string()
                .max(200)
                .required()
                .label("Operation description")
                .nullable(),
            opeQuantity: number()
                .min(0)
                .required()
                .typeError("Invalid quantity")
                .nullable(),
            opePrice: number()
                .min(0)
                .required()
                .typeError("Invalid unit price")
                .nullable(),
        });

        const contactExternSchema = object().shape({
            contactType: string().required().label("Contact type").nullable(),
            contactLastname: string()
                .required()
                .label("Contact lastname")
                .nullable(),
            contactFirstname: string()
                .required()
                .label("Contact firstname")
                .nullable(),
            contactEmail: string()
                .email()
                .required()
                .label("Contact email")
                .nullable(),
        });

        const contactMemberSchema = object().shape({
            contactType: string().required().label("Contact type").nullable(),
            contactName: string().required().label("Contact name").nullable(),
            contactEmail: string().email().label("Contact email").nullable(),
        });
        const closeContactsModal = () => {
            hideModal(addContactModalRef.value);
        };

        const closeOperationModal = () => {
            hideModal(addOperationRef.value);
        };

        onMounted(() => {
            setCurrentPageBreadcrumbs("Accounting", []);
        });

        return {
            operationSchema,
            closeOperationModal,
            addOperationRef,
            addContactModalRef,
            contactExternSchema,
            contactMemberSchema,
            closeContactsModal,
        };
    },
    mounted() {
        this.loading = false;
        this.fetchFiltersData();
        this.perm_pos = localStorage.getItem("perm_pos") as any;
    },
    methods: {
        setDefaultDate(e: any) {
            let date = "";
            let year = new Date().getFullYear();
            if (e === null) {
                date = moment(new Date(2023, 0, 1)).format("YYYY-MM-DD");
                this.billerFrom = date;
                this.billerFromYear = year;
            }
            this.getBillerInfos();
        },
        disabledDate(date) {
            const disabledDate = new Date("2022-12-31");
            return date < disabledDate;
        },
        setOperation(e: any) {
            this.editingOpe = true;
            this.editingOpeId = e.pk;
            this.newOperation = {
                is_debit: e.debit === 0 ? "credit" : "debit",
                description: e.description,
                quantity: e.quantity,
                unit_price: e.unit_price < 0 ? e.unit_price * -1 : e.unit_price,
                perimeter: e.perimeter,
                type: e.type,
            };
        },
        setSupertype(type: string, scope: string) {
            if (scope === "due") {
                this.dueSupertype = type;
            } else {
                this.priceCatSupertype = type;
            }
        },
        checkDueTypePriceCat() {
            setTimeout(() => {
                if (
                    this.billerDues.length === 0 &&
                    this.billerPriceCategories.length === 0
                ) {
                    this.dueTypesFiltered = [
                        ...this.dueTypes,
                        ...this.promTypes,
                    ];
                    this.priceCategoriesFiltered = [
                        ...this.priceCategories,
                        ...this.promPriceCategories,
                    ];
                } else if (
                    this.billerDues.length > 0 &&
                    this.billerPriceCategories.length === 0
                ) {
                    if (this.dueSupertype === "Due") {
                        this.dueTypesFiltered = this.dueTypes;
                        this.priceCategoriesFiltered = this.priceCategories;
                    } else {
                        this.dueTypesFiltered = this.promTypes;
                        this.priceCategoriesFiltered = this.promPriceCategories;
                    }
                } else if (
                    this.billerDues.length === 0 &&
                    this.billerPriceCategories.length > 0
                ) {
                    if (this.priceCatSupertype === "Due") {
                        this.dueTypesFiltered = this.dueTypes;
                        this.priceCategoriesFiltered = this.priceCategories;
                    } else {
                        this.dueTypesFiltered = this.promTypes;
                        this.priceCategoriesFiltered = this.promPriceCategories;
                    }
                } else {
                    if (
                        this.dueSupertype === "Due" &&
                        this.priceCatSupertype === "Due"
                    ) {
                        this.dueTypesFiltered = this.dueTypes;
                        this.priceCategoriesFiltered = this.priceCategories;
                    } else {
                        this.dueTypesFiltered = this.promTypes;
                        this.priceCategoriesFiltered = this.promPriceCategories;
                    }
                }
            }, 50);
        },
        setContactValues(contact: any) {
            this.editing = true;
            this.newContact.id = this.newContact.userprofile.id = contact.id;
            this.newContact.type = contact.type;
            this.newContactName =
                contact.userprofile.firstname +
                " " +
                contact.userprofile.lastname;
            this.newContact.userprofile.lastname = contact.userprofile.lastname;
            this.newContact.userprofile.firstname =
                contact.userprofile.firstname;
            this.newContact.email = contact.email;
        },
        formatCurrency(value: any) {
            return (
                value
                    .toLocaleString("fr-FR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        useGrouping: true,
                        currencyDisplay: "symbol",
                    })
                    .replace(/\u202F/g, ".") + " €"
            );
        },
        fetchFiltersData() {
            membersApi.getBailliages().then((res) => {
                var bailliages = res.data;
                bailliages.map((bailliage: any) => {
                    if (bailliage.type === "National") {
                        this.nBailliages.push(bailliage);
                    }
                });
                this.nBailliages.unshift({ id: "all", label: "All" });
                if (this.perm_pos === "2") {
                    this.billerBailliage = parseInt(
                        localStorage.getItem("bailliage") as any
                    ) as any;
                    this.isBailli = true;
                } else {
                    this.billerBailliage = this.nBailliages[0].id;
                }
                this.getBillerInfos();
            });
            membersApi.getDuesTypes({ p: 1, presult: 9999 }).then((res) => {
                this.dueTypes = res.data.object_list;
                promotionsApi
                    .getPromotionsTypes({ p: 1, presult: 9999 })
                    .then((response) => {
                        this.promTypes = response.data.object_list;
                    });
            });

            membersApi
                .getPriceCategories({ p: 1, presult: 9999 })
                .then((res) => {
                    this.priceCategories = res.data.object_list;
                    promotionsApi
                        .getPromotionsPriceCategories({ p: 1, presult: 9999 })
                        .then((response) => {
                            this.promPriceCategories =
                                response.data.object_list;
                            this.priceCategories.sort((a: any, b: any) =>
                                a.label > b.label ? 1 : -1
                            );
                        });
                });

            accountingApi
                .listOperationsTypes({ p: 1, presult: 9999 })
                .then((res) => {
                    this.operations_types = res.data.object_list;
                });

            setTimeout(() => {
                this.promTypes = this.promTypes.filter(
                    (type: any) => type.code !== "NOMINATION"
                );
                this.dueTypes.map((dueType: any) => {
                    dueType.supertype = "Due";
                });
                this.promTypes.map((promType: any) => {
                    promType.supertype = "Promotion";
                });
                this.priceCategories.map((priceCat: any) => {
                    priceCat.supertype = "Due";
                });
                this.promPriceCategories.map((priceCat: any) => {
                    priceCat.supertype = "Promotion";
                });
                this.checkDueTypePriceCat();
            }, 1500);
        },
        getBillerInfos() {
            this.loading = true;

            if (this.billerBailliage !== "all") {
                this.disabledAll = false;
                this.billerOperations = [];
                this.billerSynthesis = [];
                var payload = {
                    tab: "operations",
                    filters: {
                        national_bailliage: this.billerBailliage,
                    },
                } as any;

                this.billerFrom !== "" && this.billerFrom !== null
                    ? (payload.filters.start_date = this.billerFrom)
                    : delete payload.filters.start_date;
                this.billerTo !== "" && this.billerTo !== null
                    ? (payload.filters.end_date = this.billerTo)
                    : delete payload.filters.end_date;
                this.billerPerimeter !== "" && this.billerPerimeter !== null
                    ? (payload.filters.perimeter = this.billerPerimeter)
                    : delete payload.filters.perimeter;
                this.billerDues.length > 0
                    ? (payload.filters.type = this.billerDues)
                    : delete payload.filters.type;
                this.billerPriceCategories.length > 0
                    ? (payload.filters.price_category =
                          this.billerPriceCategories)
                    : delete payload.filters.price_category;

                this.listContacts();

                accountingApi.listOperations(payload).then((res: any) => {
                    if (res.success) {
                        this.billerOperations = res.data.operations;

                        payload.tab = "synthese";
                        accountingApi
                            .listOperations(payload)
                            .then((response: any) => {
                                if (response.success) {
                                    this.billerSynthesis =
                                        response.data.operations;
                                    this.cdrCardValue =
                                        this.billerSynthesis[2].cdr;
                                    this.omgdCardValue =
                                        this.billerSynthesis[2].omgd;
                                    this.globalCardValue =
                                        this.billerSynthesis[2].global;
                                } else {
                                    ElMessage({
                                        type: "error",
                                        message:
                                            "An error occurred while loading synthesis.",
                                    });
                                }
                                this.loading = false;
                            });
                    } else {
                        ElMessage({
                            type: "error",
                            message:
                                "An error occurred while loading operations.",
                        });
                    }
                });
            } else {
                this.disabledAll = true;
                this.intoOperations = true;
                this.intoSynthese = false;
                this.intoContacts = false;
                document.getElementById("balance-btn")?.classList.add("active");
                document.getElementById("operations")?.classList.add("active");
                document.getElementById("operations")?.classList.add("show");

                payload = {
                    tab: "operations",
                    filters: {
                        national_bailliage: "all",
                        year: this.billerFromYear,
                    },
                } as any;

                accountingApi.listOperations(payload).then((res: any) => {
                    if (res.success) {
                        this.allBailliagesData = res.data.operations;
                        this.cdrCardValue = this.allBailliagesData[1].cdr;
                        this.omgdCardValue = this.allBailliagesData[1].omgd;
                        this.globalCardValue = this.allBailliagesData[1].global;
                        this.loading = false;
                    } else {
                        ElMessage({
                            type: "error",
                            message:
                                "An error occurred while loading operations.",
                        });
                    }
                });
            }
        },
        downloadPDF(pdf: string) {
            if (pdf === "all") {
                accountingApi
                    .downloadAll({ year: this.billerFromYear })
                    .then((res: any) => {
                        if (res.success)
                            window.open(res.data.excel_url, "_blank");
                        else
                            ElMessage({
                                type: "error",
                                message:
                                    "An error occurred while downloading the Excel file.",
                            });
                    });
            } else {
                var payload = {
                    tab: "operations",
                    filters: {
                        national_bailliage: this.billerBailliage,
                    },
                } as any;

                this.billerFrom !== "" && this.billerFrom !== null
                    ? (payload.filters.start_date = this.billerFrom)
                    : delete payload.filters.start_date;
                this.billerTo !== "" && this.billerTo !== null
                    ? (payload.filters.end_date = this.billerTo)
                    : delete payload.filters.end_date;
                this.billerPerimeter !== "" && this.billerPerimeter !== null
                    ? (payload.filters.perimeter = this.billerPerimeter)
                    : delete payload.filters.perimeter;
                this.billerDues.length > 0
                    ? (payload.filters.type = this.billerDues)
                    : delete payload.filters.type;
                this.billerPriceCategories.length > 0
                    ? (payload.filters.price_category =
                          this.billerPriceCategories)
                    : delete payload.filters.price_category;

                if (pdf === "balance") {
                    accountingApi.downloadBalance(payload).then((res: any) => {
                        if (res.success)
                            window.open(res.data.balance_url, "_blank");
                        else
                            ElMessage({
                                type: "error",
                                message:
                                    "An error occurred while downloading balance.",
                            });
                    });
                }
                if (pdf === "balance_details") {
                    accountingApi
                        .downloadBalanceDetails(payload)
                        .then((res: any) => {
                            if (res.success)
                                window.open(res.data.balance_url, "_blank");
                            else
                                ElMessage({
                                    type: "error",
                                    message:
                                        "An error occurred while downloading balance details.",
                                });
                        });
                }
                if (pdf === "synthese") {
                    accountingApi.downloadSynthese(payload).then((res: any) => {
                        if (res.success)
                            window.open(res.data.synthesis_url, "_blank");
                        else
                            ElMessage({
                                type: "error",
                                message:
                                    "An error occurred while downloading synthesis.",
                            });
                    });
                }
            }
        },
        addOperation(values: any) {
            const payload = {
                is_credit: values.opeIsDebit === "credit" ? true : false,
                perimeter: values.opePerimeter,
                type: values.opeType,
                description: values.opeDescription,
                quantity: parseInt(values.opeQuantity),
                unit_price: values.opePrice,
                national_bailliage: this.billerBailliage,
            };

            if (!this.editingOpe) {
                accountingApi.addOperation(payload).then((res: any) => {
                    if (res.success) {
                        ElMessage({
                            type: "success",
                            message: "Operation added successfully.",
                        });
                        this.getBillerInfos();
                    } else {
                        ElMessage({
                            type: "error",
                            message:
                                "An error occurred while adding operation.",
                        });
                    }
                });
            } else {
                accountingApi
                    .updateOperation(payload, this.editingOpeId)
                    .then((res: any) => {
                        if (res.success) {
                            ElMessage({
                                type: "success",
                                message: "Operation edited successfully.",
                            });
                            this.getBillerInfos();
                        } else {
                            ElMessage({
                                type: "error",
                                message:
                                    "An error occurred while editing operation.",
                            });
                        }
                    });
            }
            this.closeOperationModal();
            this.resetForm("resetOperationForm");
        },
        deleteOperation(id: any) {
            accountingApi.deleteOperation(id).then((res) => {
                if (res.success) {
                    ElMessage({
                        type: "success",
                        message: "Operation deleted successfully.",
                    });
                    this.getBillerInfos();
                } else {
                    ElMessage({
                        type: "error",
                        message: "An error occured while deleting operation.",
                    });
                }
            });
        },
        listContacts() {
            accountingApi
                .listContacts({ national_bailliage: this.billerBailliage })
                .then((res: any) => {
                    if (res.success) {
                        this.main_contacts = res.data.main_contacts;
                        this.additional_contacts = res.data.additional_contacts;
                    } else {
                        ElMessage({
                            type: "error",
                            message:
                                "An error occurred while getting contacts.",
                        });
                    }
                });
        },
        addContact(values: any) {
            if (!this.editing) {
                this.closeContactsModal();
                var payload = {};
                if (values.contactType === "extern") {
                    payload = {
                        national_bailliage: this.billerBailliage,
                        firstname: values.contactFirstname,
                        lastname: values.contactLastname,
                        email: values.contactEmail,
                    };
                } else {
                    payload = {
                        national_bailliage: this.billerBailliage,
                        member_id: values.contactName,
                    };
                }

                accountingApi.addContact(payload).then((res: any) => {
                    if (res.success) {
                        this.listContacts();
                        this.closeContactsModal();
                        ElMessage({
                            type: "success",
                            message: "Contact added.",
                        });
                    } else {
                        ElMessage({
                            type: "error",
                            message: "An error occurred while adding contact.",
                        });
                    }
                });
            } else {
                if (values.contactType === "extern") {
                    payload = {
                        firstname: values.contactFirstname,
                        lastname: values.contactLastname,
                        email: values.contactEmail,
                    };
                } else {
                    payload = {
                        member_id: values.contactName,
                    };
                }

                accountingApi
                    .updateContact(payload, this.newContact.id)
                    .then((res: any) => {
                        if (res.success) {
                            this.listContacts();
                            this.closeContactsModal();
                            ElMessage({
                                type: "success",
                                message: "Contact updated.",
                            });
                        } else {
                            ElMessage({
                                type: "error",
                                message:
                                    "An error occurred while updating contact.",
                            });
                        }
                    });
            }
        },
        deleteContact(id: string) {
            accountingApi.deleteContact(id).then((res) => {
                if (res.success) {
                    this.listContacts();
                    ElMessage({ type: "success", message: "Contact deleted." });
                } else {
                    ElMessage({
                        type: "error",
                        message: "An error occurred while deleting contact.",
                    });
                }
            });
        },
        resetForm(id) {
            this.newOperation = {
                is_debit: "",
                description: "",
                quantity: "",
                unit_price: "",
                perimeter: "",
                type: "",
            };

            this.newContactName = "";

            this.newContact = {
                id: "",
                type: "member",
                userprofile: {
                    id: "",
                    lastname: "",
                    firstname: "",
                },
                email: "",
            };
            setTimeout(() => {
                (document.querySelector("#" + id) as any).click();
            }, 50);
        },
        loadRemoteMembers(query) {
            return new Promise((resolve, reject) => {
                if (query !== "" && query.length >= 2) {
                    this.remoteMembers = [];
                    membersApi.listRemote({ query: query }).then((response) => {
                        if (response.success) {
                            response.data.map((remoteMember) => {
                                this.remoteMembers.push({
                                    id: remoteMember.id,
                                    firstname:
                                        remoteMember.userprofile.firstname,
                                    lastname: remoteMember.userprofile.lastname,
                                    email: remoteMember.contact
                                        ? remoteMember.contact.email
                                        : remoteMember.contact_pro
                                        ? remoteMember.contact_pro.email
                                        : "",
                                    code: remoteMember.code,
                                });
                            });
                            resolve("");
                        } else {
                            reject("");
                        }
                    });
                } else {
                    this.remoteMembers = [];
                    resolve("");
                }
            });
        },
        querySearchAsync(queryString, cb) {
            this.loadRemoteMembers(queryString).then(() => {
                cb(this.remoteMembers);
            });
        },
        handleSelect(item: any) {
            this.newContact.userprofile.lastname = item.lastname;
            this.newContact.userprofile.firstname = item.firstname;
            this.newContact.userprofile.id = item.id;
            this.newContact.email = item.email;
            this.newContactName = item.firstname + " " + item.lastname;
            this.remoteMembers = [];
        },
        getBadgeType(type: string, scope: string) {
            if (scope === "color") {
                switch (type) {
                    case "Due":
                        return "badge-due";
                    case "ACCR":
                        return "badge-accr";
                    case "new_pro_member":
                        return "badge-pro-member";
                    case "Election":
                        return "badge-election";
                    case "Transfer":
                        return "badge-transfer";
                    case "new_member":
                        return "badge-new-member";
                    case "Nomination":
                        return "badge-pro-status";
                    case "Promotion":
                        return "badge-promotion";
                    case "Promotion Honoraire":
                        return "badge-promotion-honoraire";
                    case "addresses":
                        return "badge-addresses";
                    case "Reinstatement":
                        return "badge-reinstatement";
                    case "Admission":
                        return "badge-admission";
                    case "Penalty":
                        return "badge-penalty";
                    case "Renewal":
                        return "badge-renewal";
                    case "adhesion":
                        return "badge-adhesion";
                    case "radiation":
                        return "badge-radiation";
                    case "omgd":
                        return "badge-omgd";
                    default:
                        return "badge-unknown";
                }
            } else if (scope === "text") {
                switch (type) {
                    case "ACCR":
                        return "ACCR";
                    case "Due":
                        return "Due";
                    case "new_pro_member":
                        return "Nouveau membre Pro";
                    case "Election":
                        return "Election";
                    case "Transfer":
                        return "Transfer";
                    case "new_member":
                        return "Nouveau membre";
                    case "Nomination":
                        return "Nomination";
                    case "addresses":
                        return "Adresses";
                    case "Reinstatement":
                        return "Reinstatement";
                    case "Admission":
                        return "Admission";
                    case "Penalty":
                        return "Penalty";
                    case "Promotion":
                        return "Promotion";
                    case "Promotion Honoraire":
                        return "Promotion H.";
                    case "Renewal":
                        return "Renewal";
                    case "adhesion":
                        return "Adhésion";
                    case "radiation":
                        return "Radiation";
                    case "omgd":
                        return "OMGD";
                    default:
                        return type ? type : "Unknown";
                }
            }
        },
    },
});
