import apiTool from "./index";
import ApiService from "./ApiService";

const GET_PROMOTIONS = "api/v1/promotion/list/";
const ADD_PROMOTION = "api/v1/promotion/add/";
const DELETE_PROMOTION = "api/v1/promotion/delete/";
const CANCEL_PROMOTION = "api/v1/promotion/cancel/";
const GET_SPECIFIC_PROMOTION = "api/v1/promotion/<pk>";
const GET_PRICE_CATEGORIES = "api/v1/promotion-price-category/list/"
const UPDATE_PROMOTION = "api/v1/promotion/<pk>/";
const GET_PROMOTION_TYPES = "api/v1/promotion-type/list/";

export default {
    getPromotions(payload) {
        ApiService.setHeader();
        return ApiService.post(GET_PROMOTIONS, payload).then(response => {
            return response.data;
        });
    },
    getSpecificPromotion(payload) {
        ApiService.setHeader();
        return ApiService.get(GET_SPECIFIC_PROMOTION.replace("<pk>", payload)).then(response => {
            return response.data;
        });
    },
    addPromotion(payload) {
        ApiService.setHeader();
        return ApiService.post(ADD_PROMOTION, payload).then(response => {
            return response.data;
        });
    },
    editPromotion(id, payload) {
        ApiService.setHeader();
        return ApiService.patch(UPDATE_PROMOTION.replace("<pk>", id), payload).then(response => {
            return response.data;
        });
    },
    deletePromotions(payload) {
        ApiService.setHeader();
        return ApiService.post(DELETE_PROMOTION, payload).then(response => {
            return response.data;
        });
    },
    cancelPromotion(payload) {
        ApiService.setHeader();
        return ApiService.post(CANCEL_PROMOTION, payload).then(response => {
            return response.data;
        });
    },
    getPromotionsPriceCategories(payload) {
        ApiService.setHeader();
        return ApiService.post(GET_PRICE_CATEGORIES, payload).then(response => {
            return response.data;
        });
    },
    getPromotionsTypes(payload) {
        ApiService.setHeader();
        return ApiService.post(GET_PROMOTION_TYPES, payload).then(response => {
            return response.data;
        });
    }
};
