import apiTool from "./index";
import ApiService from "./ApiService";

const LIST_BILLERS = "api/v1/biller/list/";
const LIST_OPERATIONS = "api/v1/operation/list/";
const LIST_OPERATIONS_TYPES = "api/v1/operation-type/list/";
const LIST_CONTACTS = "api/v1/biller-contact/list/";
const DELETE_CONTACT = "api/v1/biller-contact/<id>/";
const UPDATE_CONTACT = "api/v1/biller-contact/<id>/update/";
const ADD_CONTACT = "api/v1/biller-contact/add/";
const ADD_OPERATION = "api/v1/operation/add/";
const UPDATE_OPERATION = "api/v1/operation/<id>/";
const DELETE_OPERATION = "api/v1/operation/<id>/";
const PDF_BALANCE = "api/v1/accounting/balance-pdf/";
const PDF_BALANCE_DETAILS = "api/v1/accounting/balance-details-pdf/";
const PDF_SYNTHESE = "api/v1/accounting/synthesis-pdf/";
const ALL_EXCEL = "api/v1/accounting/excel-all/";

export default {
    listBillers(payload) {
        ApiService.setHeader();
        return ApiService.post(LIST_BILLERS, payload).then(response => {
            return response.data;
        });
    },
    listOperations(payload) {
        ApiService.setHeader();
        return ApiService.post(LIST_OPERATIONS, payload).then(response => {
            return response.data;
        });
    },
    listContacts(payload) {
        ApiService.setHeader();
        return ApiService.post(LIST_CONTACTS, payload).then(response => {
            return response.data;
        });
    },
    addContact(payload) {
        ApiService.setHeader();
        return ApiService.post(ADD_CONTACT, payload).then(response => {
            return response.data;
        });
    },
    updateContact(payload, id) {
        ApiService.setHeader();
        return ApiService.post(UPDATE_CONTACT.replace("<id>", id), payload).then(response => {
            return response.data;
        });
    },
    deleteContact(id) {
        ApiService.setHeader();
        return ApiService.delete(DELETE_CONTACT.replace("<id>", id)).then(response => {
            return response.data;
        });
    },
    addOperation(payload) {
        ApiService.setHeader();
        return ApiService.post(ADD_OPERATION, payload).then(response => {
            return response.data;
        });
    },
    updateOperation(payload, id) {
        ApiService.setHeader();
        return ApiService.patch(UPDATE_OPERATION.replace("<id>", id), payload).then(response => {
            return response.data;
        });
    },
    deleteOperation(id) {
        ApiService.setHeader();
        return ApiService.delete(DELETE_OPERATION.replace("<id>", id)).then(response => {
            return response.data;
        });
    },
    downloadBalance(payload) {
        ApiService.setHeader();
        return ApiService.post(PDF_BALANCE, payload).then(response => {
            return response.data;
        });
    },
    downloadBalanceDetails(payload) {
        ApiService.setHeader();
        return ApiService.post(PDF_BALANCE_DETAILS, payload).then(response => {
            return response.data;
        });
    },
    downloadSynthese(payload) {
        ApiService.setHeader();
        return ApiService.post(PDF_SYNTHESE, payload).then(response => {
            return response.data;
        });
    },
    downloadAll(payload) {
        ApiService.setHeader();
        return ApiService.post(ALL_EXCEL, payload).then(response => {
            return response.data;
        });
    },
    listOperationsTypes(payload) {
        ApiService.setHeader();
        return ApiService.post(LIST_OPERATIONS_TYPES, payload).then(response => {
            return response.data;
        });
    }
};
